<template>
  <div>
    <div class="contain1">
      <div class="flex-center title">
        网校课程
        <helpIcon style="margin-left:6px;" prompt="统计网校累计创建课程的数量，包括已上架、已下架和隐藏的课程(不包括删除的课程)"></helpIcon>
      </div>
      <div class="flex-center course-detail">
        <div class="flex-center item">
          全部
          <i class="number">{{ courseNum.course_num }}</i
          >门
        </div>
        <div class="flex-center item">
          已上架
          <i class="number">{{ courseNum.upper_course_num }}</i
          >门
        </div>
        <div class="flex-center item">
          已下架
          <i class="number">{{ courseNum.lower_course_num }}</i
          >门
        </div>
        <div class="flex-center item">
          已隐藏
          <i class="number">{{ courseNum.hide_course_num }}</i
          >门
          <helpIcon class="helpIcon" prompt="创建课程时选择不展示到网校的课程为已隐藏"></helpIcon>
        </div>
        <div class="flex-center item">
          已删除
          <i class="number">{{ courseNum.deleted_course_num }}</i
          >门
        </div>
      </div>
      <div class="pieChart">
        <pieChart :pieData="list"></pieChart>
      </div>
      <!--树状图-->
      <div class="flex-center">
        <div class="tree-item">
          <div class="t-contain">
            <div class="flex-center t1">
              总销售额
              <helpIcon style="margin-left:6px;" prompt="统计网校课程累计销售额(不包括直播打赏送礼收益)"></helpIcon>
            </div>
            <div class="t2">
              {{ courseOrderAmountAndNum.course_order_amount }}
              <i>元</i>
            </div>
          </div>

          <div class="tree-caht">
            <treeChart :float="true" :alone="true" :lists="dataset1"></treeChart>
          </div>
        </div>
        <div class="tree-item">
          <div class="t-contain">
            <div class="flex-center t1">
              总销售量
              <helpIcon style="margin-left:6px;" prompt="统计网校课程累计销售数量"></helpIcon>
            </div>
            <div class="t2">
              {{ courseOrderAmountAndNum.totalSaleNum }}
              <i>笔</i>
            </div>
          </div>

          <div class="tree-caht">
            <treeChart :alone="true" :lists="dataset2"></treeChart>
          </div>
        </div>
        <div class="tree-item">
          <div class="t-contain">
            <div class="flex-center t1">
              总访问次数
              <helpIcon style="margin-left:6px;" prompt="统计网校课程累计访问次数，包括课程详情、直播间、课程分享页(不包括已删除的课程数据)"></helpIcon>
            </div>
            <div class="t2">
              {{ visitNum.totalVisitNum }}
              <i>次</i>
            </div>
          </div>
          <div class="tree-caht">
            <treeChart :alone="true" :lists="dataset3"></treeChart>
          </div>
        </div>
      </div>
    </div>
    <!--课程排行-->
    <div class="contain2">
      <div class="flex-center tab-header">
        <span class="tag"></span>
        <span class="title">课程排行</span>
        <el-form :inline="true" class="form-inline" @submit.native.prevent>
          <el-form-item>
            <el-select v-model="post1.course_type">
              <el-option v-for="item in courseCateGory" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item>
            <el-date-picker
              :value="post1.time"
              @input="rankChangeTime(arguments[0], 'post1')"
              type="daterange"
              align="right"
              unlink-panels
              value-format="timestamp"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :pickerOptions="pickerOptions"
            ></el-date-picker>
          </el-form-item>-->
        </el-form>
      </div>
      <el-tabs v-model="post1.type" type="card" class="el_tab">
        <el-tab-pane label="访客榜TOP5课程" name="1"></el-tab-pane>
        <el-tab-pane label="支付榜TOP5课程" name="2"></el-tab-pane>
      </el-tabs>
      <pagination2 :option="post1" url="/statistics/courseBank">
        <template v-slot:default="{ tableData: { bankList } }">
          <el-table
            :data="bankList"
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333'
            }"
          >
            <el-table-column label="排名" prop="name" width="90px">
              <template slot-scope="scope">{{ scope.$index + 1 }}</template>
            </el-table-column>
            <el-table-column label="课程" prop="course_name">
              <template slot-scope="{ row }">
                <div class="flex-center course-info">
                  <div class="cover">
                    <img :src="row.course_img" alt />
                  </div>
                  <div class="info">
                    <div class="name">{{ row.course_name }}</div>
                    <div class="price">{{ row.course_price > 0 ? `￥${row.course_price}` : '免费' }}</div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="访问次数" prop="visit_user_num">
              <template slot="header">
                <div class="flex-center">
                  访问次数
                  <helpIcon style="margin-left:6px;" prompt="全部学员访问课程详情的总次数"></helpIcon>
                </div>
              </template>
            </el-table-column>
            <template v-if="post1.type == 1">
              <el-table-column label="订单数" prop="course_order_num">
                <template slot="header">
                  <div class="flex-center">
                    订单数
                    <helpIcon style="margin-left:6px;" prompt="该课程的已支付订单，包含已作废、已过期的订单"></helpIcon>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="转化率" prop="conversion_rate">
                <template slot="header">
                  <div class="flex-center">
                    转化率
                    <helpIcon style="margin-left:6px;" prompt="转化率=订单数/访客次数*100，保留两位小数"></helpIcon>
                  </div>
                </template>
              </el-table-column>
            </template>
            <template v-else>
              <el-table-column label="付费人数" prop="paid_user_num"></el-table-column>
              <el-table-column label="总收入(元)" prop="amount">
                <template slot="header">
                  <div class="flex-center">
                    总收入(元)
                    <helpIcon style="margin-left:6px;" prompt="统计网校课程订单收入，不包括直播打赏送礼金额"></helpIcon>
                  </div>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </template>
      </pagination2>
    </div>
    <!--课程分析-->
    <div class="contain3">
      <div class="flex-center tab-header">
        <span class="title">课程分析</span>
        <el-form :inline="true" class="form-inline" @submit.native.prevent>
          <el-form-item>
            <el-select v-model="post2.course_type">
              <el-option v-for="item in courseCateGory" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item>
            <el-date-picker
              :value="post2.time"
              @input="rankChangeTime(arguments[0], 'post2')"
              type="daterange"
              align="right"
              unlink-panels
              value-format="timestamp"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :pickerOptions="pickerOptions"
            ></el-date-picker>
          </el-form-item>-->
        </el-form>
      </div>
      <pagination2 :option="post2" url="/statistics/courseAnalysisList">
        <template v-slot:default="{ tableData: { list } }">
          <el-table
            :data="list"
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333'
            }"
          >
            <el-table-column label="课程名称" prop="course_name">
              <template slot-scope="{ row }">
                <div class="flex-center course-info">
                  <div class="cover">
                    <img :src="row.course_img" alt />
                  </div>
                  <div class="info">
                    <div class="name">{{ row.course_name }}</div>
                    <div class="price">{{ row.course_price > 0 ? `￥${row.course_price}` : '免费' }}</div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="访问次数" prop="visit_user_num">
              <template slot="header">
                <div class="flex-center">
                  访问次数
                  <helpIcon style="margin-left:6px;" prompt="全部学员访问课程详情的总次数"></helpIcon>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="订单数" prop="course_order_num">
              <template slot="header">
                <div class="flex-center">
                  订单数
                  <helpIcon style="margin-left:6px;" prompt="该课程的已支付订单，包含已作废、已过期的订单"></helpIcon>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="总收入" prop="amount">
              <template slot="header">
                <div class="flex-center">
                  总收入
                  <helpIcon style="margin-left:6px;" prompt="统计网校课程订单收入，不包括直播打赏送礼金额"></helpIcon>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="转化率" prop="conversion_rate">
              <template slot="header">
                <div class="flex-center">
                  转化率
                  <helpIcon style="margin-left:6px;" prompt="转化率=订单数/访客次数*100，保留两位小数"></helpIcon>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </pagination2>
    </div>
  </div>
</template>
<script>
import helpIcon from '@/components/helpIcon'
import pieChart from '@/components/dataAnalysis/pieChart'
import treeChart from '@/components/dataAnalysis/tree'

export default {
  name: 'courseAnalysis',

  components: {
    helpIcon,
    pieChart,
    treeChart
  },

  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 86400000
        }
      },

      courseNum: {
        // course_num: "全部课程",
        // hide_course_num: "已隐藏",
        // upper_course_num: "已上架",
        // lower_course_num: "已下架",
        // deleted_course_num: "已删除",
        // live_course_num: "直播课订单",
        // private_course_num: "小班课订单",
        // series_course_num: "系列课订单",
        // recording_course_num: "录播课订单",
      },

      courseOrderAmountAndNum: {
        // large_course_order_amount: "直播课销售额",
        // private_course_order_amount: "小班课销售额",
        // record_course_order_amount: "录播课销售额",
        // series_course_order_amount: "系列课销售额",
        // course_order_amount: "总销售额",
        // totalSaleNum: "总销售数量",
        // large_course_order_num: "直播课销售数量",
        // private_course_order_num: "小班课销售数量",
        // record_course_order_num: "录播课销售数量",
        // series_course_order_num: "系列课销售数量",
      },

      visitNum: {
        // totalVisitNum: "总访问次数",
        // liveVisitNum: "直播课访问次数",
        // privateVisitNum: "小班课访问次数",
        // recordVisitNum: "录播课访问次数",
        // seriesVisitNum: "系列课访问次数",
      },

      // 课程占比
      list: [],

      // 总销售额
      dataset1: [],
      // 总销售数量
      dataset2: [],
      // 总访问次数
      dataset3: [],

      // 课程排行
      post1: {
        course_type: 0,
        time: '',
        type: '1',
        startTime: '',
        endTime: ''
      },
      // 课程分析
      post2: {
        course_type: 0,
        course_id: this.$route.query.course_id,
        name: '',
        time: '',
        startTime: '',
        endTime: ''
      },
      courseCateGory: [
        {
          name: '全部类型',
          id: 0
        },
        {
          name: '直播课',
          id: 1
        },
        {
          name: '录播课',
          id: 3
        },
        {
          name: '小班课',
          id: 2
        },
        {
          name: '系列课',
          id: 4
        }
      ]
    }
  },

  created() {
    this.getData()
  },

  methods: {
    getData() {
      this.$http({
        url: '/statistics/courseBaseData',
        callback: ({ data: { courseNum, courseOrderAmountAndNum, visitNum } }) => {
          this.visitNum = visitNum
          this.courseNum = courseNum
          this.courseOrderAmountAndNum = courseOrderAmountAndNum

          // 课程占比
          this.list = [
            {
              value: courseNum.live_course_num,
              name: '直播课',
              itemStyle: { color: '#0093F8' }
            },
            {
              value: courseNum.recording_course_num,
              name: '录播课',
              itemStyle: { color: '#FED74C' }
            },
            {
              value: courseNum.private_course_num,
              name: '小班课',
              itemStyle: { color: '#66B358' }
            },
            {
              value: courseNum.series_course_num,
              name: '系列课',
              itemStyle: { color: '#FA6400' }
            }
          ]

          // 总销售额
          this.dataset1 = [
            ['product'],
            ['直播课', courseOrderAmountAndNum.large_course_order_amount],
            ['录播课', courseOrderAmountAndNum.record_course_order_amount],
            ['小班课', courseOrderAmountAndNum.private_course_order_amount],
            ['系列课', courseOrderAmountAndNum.series_course_order_amount]
          ]
          // 总销售数量
          this.dataset2 = [
            ['product'],
            ['直播课', courseOrderAmountAndNum.large_course_order_num],
            ['录播课', courseOrderAmountAndNum.record_course_order_num],
            ['小班课', courseOrderAmountAndNum.private_course_order_num],
            ['系列课', courseOrderAmountAndNum.series_course_order_num]
          ]
          // 总访问次数
          this.dataset3 = [
            ['product'],
            ['直播课', visitNum.liveVisitNum],
            ['录播课', visitNum.recordVisitNum],
            ['小班课', visitNum.privateVisitNum],
            ['系列课', visitNum.seriesVisitNum]
          ]
        }
      })
    },

    // 时间变化
    rankChangeTime(value, name) {
      this[name] = Object.assign({}, this[name], {
        startTime: value ? value[0] / 1000 : '',
        endTime: value ? value[1] / 1000 : '',
        time: value || '',
        area_type: value ? 3 : 1
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.contain1 {
  padding: 20px;
  margin-top: 20px;
  background: #fff;
}
.title {
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  color: rgba(51, 51, 51, 1);
}
.course-detail {
  margin-top: 10px;
  .item {
    font-size: 12px;
    margin-right: 40px;
    position: relative;
    align-items: baseline;
    color: rgba(51, 51, 51, 1);
    > i {
      margin: 0 5px;
      font-size: 24px;
      font-weight: bold;
      line-height: 31px;
      position: relative;
      top: 2px;
      color: rgba(51, 51, 51, 1);
    }
    .helpIcon {
      position: absolute;
      top: -4px;
      right: -11px;
    }
  }
}
.pieChart {
  width: 515px;
  height: 179px;
  margin: 20px auto 60px;
  position: relative;
}
.tree-item {
  flex: 1;
  .t-contain {
    .t1 {
      font-size: 14px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
      line-height: 19px;
    }
    .t2 {
      margin-top: 10px;
      font-size: 24px;
      font-weight: bold;
      line-height: 31px;
      color: rgba(51, 51, 51, 1);
      i {
        font-size: 12px;
        line-height: 16px;
        margin-left: 5px;
        color: rgba(51, 51, 51, 1);
      }
    }
  }
  .tree-caht {
    width: 100%;
    height: 280px;
    position: relative;
  }
}
.contain2,
.contain3 {
  padding: 20px;
  margin-top: 20px;
  background: #fff;
}
.course-info {
  .cover {
    width: 68px;
    height: 38px;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .info {
    flex: 1;
    overflow: hidden;
    margin-left: 10px;
    .name {
      overflow: hidden;
      font-size: 14px;
      line-height: 19px;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: rgba(51, 51, 51, 1);
    }
    .price {
      font-size: 12px;
      margin-top: 6px;
      line-height: 16px;
      color: rgba(255, 53, 53, 1);
    }
  }
}
.tab-header {
  margin-bottom: 30px;
  .tag {
    width: 2px;
    height: 20px;
    background: rgba(27, 157, 151, 1);
  }
  .title {
    flex: 1;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    margin-left: 10px;
    color: rgba(51, 51, 51, 1);
  }
  ::v-deep .el-input,
  ::v-deep .el-range-input {
    font-size: 12px;
    line-height: 36px;
  }
  ::v-deep .el-range-separator,
  ::v-deep .el-date-editor .el-range__icon,
  ::v-deep .el-date-editor .el-range__close-icon {
    line-height: 33px;
  }
  ::v-deep .el-form-item__content {
    height: 36px;
  }
  ::v-deep .el-input__inner,
  ::v-deep .el-input {
    width: 120px;
  }
  ::v-deep .el-date-editor {
    width: 220px;
  }
  ::v-deep .el-form-item {
    margin-bottom: 0;
  }
}
::v-deep .el_tab {
  margin-bottom: 25px;
  .el-tabs__header {
    border: 0;
  }
  .el-tabs__nav {
    border-bottom: 1px solid #e4e7ed;
  }
}
</style>
